import React from 'react';
import styled from 'styled-components';

import GearIcon from 'shared/designTokens/icons/ui/small/GearIcon';
import CoinIcon from 'shared/designTokens/icons/ui/small/CoinIcon';
import DashboardIcon from 'shared/designTokens/icons/ui/small/DashboardIcon';
import VerifiedIcon from 'shared/designTokens/icons/ui/small/VerifiedIcon';

import { useSelectActiveTeam } from 'app/src/selectors/team';
import { useSelectUser } from 'app/src/selectors/user';

import { LabelTypes } from 'app/src/components/ui/Label';
import ListMenu from 'app/src/components/ui/ListMenu';
import Tooltip from 'shared/components/Tooltip';

import _ from 'shared/copy';

const StyledVerifiedIcon = styled(VerifiedIcon)`
	width: 18px;
	height: 18px;
	color: ${props => props.theme.pokiBlue};
`;

const VerifiedTooltip = styled(Tooltip)`
	height: 18px;
	margin-top: -2px;
`;

const DeveloperMenu = () => {
	const activeTeam = useSelectActiveTeam();
	const user = useSelectUser();

	const buttons = [
		{
			icon: DashboardIcon,
			to: `/${activeTeam.code}`,
			name: _`dashboard`,
			needsPermissions: [activeTeam.verified ? ['can_read_all_games', 'can_read_owned_games'] : ['can_read_all_games']],
		},
		{
			icon: CoinIcon,
			name: _`finance`,
			to: `/${activeTeam.code}/finance`,
			isActiveFunction: currentPath => currentPath.startsWith(`/${activeTeam.code}/finance`),
			needsPermissions: [['can_read_all_invoices', 'can_read_owned_invoices']],
		},
		{
			icon: GearIcon,
			name: _`settings`,
			isActiveFunction: currentPath => currentPath.startsWith(`/${activeTeam.code}/settings`),
			children: [
				{
					name: _`general`,
					to: `/${activeTeam.code}/settings`,
					needsPermissions: [['can_edit_all_teams', 'can_edit_owned_teams']],
				},
				{
					name: _`users`,
					to: `/${activeTeam.code}/settings/users`,
					needsPermissions: [['can_read_all_users', 'can_read_owned_users']],
				},
				{
					name: _`billing`,
					to: `/${activeTeam.code}/settings/billing`,
					needsPermissions: [['can_edit_all_billing', 'can_edit_owned_billing']],
				},
				// {
				// 	name: `${_`revenueSharing`}`,
				// 	to: `/${activeTeam.code}/settings/revenue-sharing-legacy`,
				// 	needsPermissions: [['can_read_all_teams']],
				// },
				{
					name: _`revenueSharing`,
					to: `/${activeTeam.code}/settings/revenue-sharing`,
					needsPermissions: [['can_read_all_teams']],
					label: {
						type: LabelTypes.NEW,
					},
				},
				{
					name: _`additionalPayments`,
					to: `/${activeTeam.code}/settings/additional-payments`,
					needsPermissions: [['can_read_all_teams', 'can_read_all_minimum_guarantees', 'can_read_all_upfront_payments', 'can_read_all_one_off_payments', 'can_read_all_minimum_guarantees', 'can_read_all_upfront_payments', 'can_read_all_one_off_payments']],
				},
			],
		},
	];

	const showIsVerified = activeTeam.verified && user.role === 'admin';

	return (
		<ListMenu
			title={(
				<>
					{activeTeam.name}
					{showIsVerified && (
						<VerifiedTooltip
							placement="top"
							arrow
							content={_`verifiedDeveloper`}
						>
							<StyledVerifiedIcon title={_`verifiedDeveloper`} />
						</VerifiedTooltip>
					)}
				</>
			)}
			buttons={buttons}
		/>
	);
};

export default DeveloperMenu;
