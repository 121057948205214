import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { ignoreElements, merge, map, tap } from 'rxjs';

import GameIcon from 'shared/designTokens/icons/ui/medium/GameIcon';

import { useSelectGameChecklistLevelStatus } from 'app/src/selectors/session';
import { selectLastUploadedVersionByGame } from 'app/src/selectors/game';
import { setGameChecklistStatus } from 'app/src/actions/session';
import { getGameById, request10kGameplays } from 'app/src/epics/game';
import { useSelectUser } from 'app/src/selectors/user';
import { pushEvent } from 'app/src/utils/tracking';

import Button from 'app/src/components/ui/Button';

import { _small } from 'shared/vars';
import _ from 'shared/copy';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 16px;
	
	width: 100%;
	max-width: 444px;
	padding: 32px;

	background: ${props => props.theme.grey7};
	border-radius: 4px;
`;

const UpperContainer = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	align-self: stretch;
	gap: 16px;

	${_small} {
		flex-direction: row;
	}
`;

const LeftContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	flex: 1 0 0;

	${_small} {
		order: 1;
	}
`;

const StyledHeader = styled.h4`
	font: 700 16px/20px "Proxima Nova";
	margin: 0;
`;

const Buttons = styled.div`
	display: flex;
	gap: 16px;
`;

const StyledGameIcon = styled(GameIcon)`
	width: 64px;
	height: 64px;

	${_small} {
		width: 108px;
		height: 108px;
		order: 2;
	}
`;

const StyledLink = styled.a`
	color: ${props => props.theme.blue};
	text-decoration: none;
	font: 700 16px/24px "Proxima Nova";
`;

const FooterText = styled.div`
	color: ${props => props.theme.grey3};
	font: 400 12px/16px "Proxima Nova";
`;

const Error = styled.div`
	color: ${props => props.theme.rose1};
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;

	& + & {
		margin-top: 0;
	}
`;

const WebFitInspectorCheck = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const WebFitInspectorCheckList = styled.ul`
	padding: 0 0 0 16px;
	margin: 0;

	li::marker {
		color: ${props => props.theme.rose1};
	}
`;

const WebFitInspectorCheckListItem = styled.li``;

const WebFitInspectorCheckListItemText = styled.div`
	color: ${props => props.theme.rose1};
`;

const WEB_FIT_REQUESTED = 'poki_web_fit_requested';
const STEPS_TO_CHECK = {
	'sdk-basics-gameplay-start': {
		text: 'SDK: no gameplayStart() event fired at the start of gameplay.',
		status: 'NOT_STARTED',
	},
	'sdk-basics-game-loading-finished': {
		text: 'SDK: no gameLoadingFinished() event fired when the game is fully loaded.',
		status: 'NOT_STARTED',
	},
	'mobile-optimization-playable': {
		text: 'The game is not playable on mobile',
		status: 'NOT_STARTED',
	},
};

const Level3Step2 = ({ disabled, game }) => {
	const dispatch = useDispatch();

	const containerRef = useRef(null);

	const user = useSelectUser();
	const levelStatus = useSelectGameChecklistLevelStatus(game.id, 3);
	const { pending, failed } = {
		pending: levelStatus?.[1]?.status === 'pending',
		failed: levelStatus?.[1]?.status === 'failed',
	};

	const [error, setError] = useState(null);
	const [hasStepsToCheck, setHasStepsToCheck] = useState(Object.values(STEPS_TO_CHECK).some(step => step.status !== 'PASSED'));

	const { inspector_checklist = { ...STEPS_TO_CHECK }, id } = selectLastUploadedVersionByGame(game?.versions) || {};

	const updatedStepStatuses = Object.keys(STEPS_TO_CHECK).reduce((updatedSteps, step) => {
		updatedSteps[step] = {
			...STEPS_TO_CHECK[step],
			status: inspector_checklist[step] || STEPS_TO_CHECK[step].status,
		};

		return updatedSteps;
	}, {});

	// Refresh game data when the window is focused, this might be the dev
	// coming back from the Inspector after doing the checklist.
	useEffect(() => {
		if (!game.id) {
			return;
		}

		// Always refresh game data when the modal is opened to get the latest Inspector status.
		dispatch(getGameById.fetch(game.id));

		const listener = window.addEventListener('focus', () => {
			dispatch(getGameById.fetch(game.id));
		});
		return () => window.removeEventListener('focus', listener);
	}, []);

	useEffect(() => {
		if (!game.id) {
			return;
		}

		if (game.stage !== WEB_FIT_REQUESTED) {
			dispatch(setGameChecklistStatus({ gameId: game.id, level: 3, step: 2, status: 'not_started' }));
		}
	}, [game]);

	useEffect(() => {
		setHasStepsToCheck(Object.values(updatedStepStatuses).some(step => step.status !== 'PASSED'));
	}, [updatedStepStatuses]);

	const handleRequestWebFitTest = () => {
		setError(null);

		if (hasStepsToCheck) {
			pushEvent('gameCreateFlow', 'requestWebFitTestIncomplete', {
				gameId: game.id,
				checklist: updatedStepStatuses,
			});
			dispatch(setGameChecklistStatus({ gameId: game.id, level: 3, step: 2, status: 'failed' }));

			return;
		}

		pushEvent('gameCreateFlow', 'requestWebFitTest', { gameId: game.id });

		dispatch(request10kGameplays.fetch({ gameId: game.id }, ({ success$, error$ }) => merge(
			success$.pipe(
				map(() => setGameChecklistStatus({ gameId: game.id, level: 3, step: 2, status: 'pending' })),
			),
			error$.pipe(
				tap(({ payload: { result: { response: { errors: [err] } } } }) => {
					setError(err);
				}),
				ignoreElements(),
			),
		)));
	};

	const onClickTermsAndConditions = () => {
		pushEvent('gameCreateFlow', 'termsAndConditions', {
			gameId: game.id,
		});
	};

	const handleOpenInspector = () => {
		const isAdmin = user.role === 'admin' || user.role === 'read-only-admin';
		window.open(`https://inspector.poki.dev/?game=poki-${id}${isAdmin ? '&analytics=false' : ''}`, '_blank');
	};

	return (
		<Container ref={containerRef}>
			<UpperContainer>
				<StyledGameIcon />
				<LeftContainer>
					<StyledHeader>
						{pending ? _`preppingGame` : _`liveForAWeek`}
					</StyledHeader>
					{pending ? _`notifiedByEmail` : (
						<>
							<div>
								{_`gameWillBeLive`}
							</div>
							<div>
								Before requesting a test, check if your game complies with our <StyledLink onClick={onClickTermsAndConditions} href="/2024.03.13_Terms_and_Conditions_Poki_for_Developers.pdf" download="2024-03-13 Terms and Conditions Poki for Developers.pdf" target="_blank">Terms & Conditions</StyledLink>. Because we care about the safety of our players.
							</div>
						</>
					)}
				</LeftContainer>
			</UpperContainer>

			{pending ? <FooterText>{_`takesAboutDays`}</FooterText>
				: (
					<Buttons>
						<Button onClick={handleRequestWebFitTest} disabled={disabled || (hasStepsToCheck && failed)}>{_`requestWebFitTest`}</Button>
					</Buttons>
				)}
			{error && <Error>{error}</Error>}

			{hasStepsToCheck && failed && (
				<WebFitInspectorCheck>
					<strong>
						Almost there! Make sure your game meets the technical requirements listed below by using the inspector.
					</strong>
					<WebFitInspectorCheckList>
						{Object.keys(updatedStepStatuses).map(step => (
							updatedStepStatuses[step].status !== 'PASSED' && (
								<WebFitInspectorCheckListItem key={step}>
									<WebFitInspectorCheckListItemText>{updatedStepStatuses[step].text}</WebFitInspectorCheckListItemText>
								</WebFitInspectorCheckListItem>
							)
						))}
					</WebFitInspectorCheckList>
					<Button onClick={handleOpenInspector}>Open Inspector</Button>
				</WebFitInspectorCheck>
			)}
		</Container>
	);
};

export default Level3Step2;
